'use strict';
jQuery(document).ready(function ($) {
  function load_js() {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.src = './assets/js/dist/script.min.js';
    head.appendChild(script);
  }

  $('#register_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('#success-message').hide();
      $('#error-message').text('');
      $('#register_form button').attr('disabled', 'true');
      var that = $('#register_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });
      that.find('[name]:checked').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });
      $.ajax({
        url: url,
        type: method,
        data: data,
        success: function (response) {
          if (response == 'success') {
            $('#success-message').show();
            $('#register_form').hide();
          } else {
            $('#register_form button').attr('disabled', 'false');
            $('#error-message').text('Failed, please try again.');
          }
        },
        error: function (response) {
          console.log(response);
          $('#register_form button').attr('disabled', 'false');
          $('#error-message').text('Failed, please try again.');
        },
      });
    });

  $('#register_shuttle_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('#shuttle-success-message').hide();
      $('#shuttle-error-message').text('');
      $('#register_shuttle_form button').attr('disabled', 'true');
      var that = $('#register_shuttle_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });
      that.find('[name]:checked').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });
      $.ajax({
        url: url,
        type: method,
        data: data,
        success: function (response) {
          console.log(response);
          if (response == 'success') {
            $('#shuttle-success-message').show();
            $('#register_shuttle_form').hide();
          } else {
            $('#register_shuttle_form button').attr('disabled', 'false');
            $('#shuttle-error-message').text('Failed, please try again.');
          }
        },
        error: function (response) {
          console.log(response);
          $('#register_shuttle_form button').attr('disabled', 'false');
          $('#shuttle-error-message').text('Failed, please try again.');
        },
      });
    });
  //expanded
  $('#toggle-expanded').on('click', function () {
    $('#intro-section').addClass('hidden');
    $('#expanded-section').removeClass('hidden');
  });

  //checkboxes
  $('#saturday_june_1st_present').on('click', function () {
    console.log(this.checked);
    if (this.checked) {
      $('#saturday_june_1st_present_icon').removeClass('hidden');
      $('#saturday_june_1st_not_present_icon').addClass('hidden');
    }
  });
  $('#saturday_june_1st_not_present').on('click', function () {
    console.log(this.checked);
    if (this.checked) {
      $('#saturday_june_1st_not_present_icon').removeClass('hidden');
      $('#saturday_june_1st_present_icon').addClass('hidden');
    }
  });
  $('#sunday_june_2nd_present').on('click', function () {
    console.log(this.checked);
    if (this.checked) {
      $('#sunday_june_2nd_present_icon').removeClass('hidden');
      $('#sunday_june_2nd_not_present_icon').addClass('hidden');
    }
  });
  $('#sunday_june_2nd_not_present').on('click', function () {
    console.log(this.checked);
    if (this.checked) {
      $('#sunday_june_2nd_not_present_icon').removeClass('hidden');
      $('#sunday_june_2nd_present_icon').addClass('hidden');
    }
  });
});
